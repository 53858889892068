<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("重置密码") }}</div>
        <div class="tips">
          <img :src="warn2Icon" width="20" height="20" alt="" />
          <div>
            {{
              translateTitle(
                "为确保您的账户安全，在您更改密码后，提现、C2C出售、支付服务和币安卡应用等功能将被禁用24小时。"
              )
            }}
          </div>
        </div>
      </div>
      <div class="form">
        <el-form
          ref="formRef"
          :rules="formRules"
          :model="formData"
          @submit.native.prevent
        >
          <el-form-item
            prop="password"
            :label="translateTitle('密码')"
            style="margin-bottom: 10px"
          >
            <el-input
              v-model="formData.password"
              :placeholder="translateTitle('请输入密码')"
              :type="showPassword ? 'text' : 'password'"
            >
              <template #suffix>
                <div class="suffix-btn">
                  <ClearIcon
                    v-if="!!formData.password"
                    style="margin-right: 10px"
                    @click="formData.password = ''"
                  />
                  <EyeIcon
                    v-if="showPassword"
                    @click="showPassword = !showPassword"
                  />
                  <EyeHideIcon v-else @click="showPassword = !showPassword" />
                </div>
              </template>
            </el-input>
            <div class="validate">
              <div class="item">
                <CheckSuccessIcon v-if="validObject.full" />
                <CheckFailIcon v-else />
                <span :class="{ success: validObject.full }">{{
                  translateTitle("6-20个字符，不包含空格")
                }}</span>
              </div>
              <div class="item">
                <CheckSuccessIcon v-if="validObject.number" />
                <CheckFailIcon v-else />
                <span :class="{ success: validObject.number }">{{
                  translateTitle("至少1个数字")
                }}</span>
              </div>
              <div class="item">
                <CheckSuccessIcon v-if="validObject.char" />
                <CheckFailIcon v-else />
                <span :class="{ success: validObject.char }">{{
                  translateTitle("至少1个字母")
                }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="password"
            :label="translateTitle('再次输入密码')"
            style="margin-bottom: 16px"
          >
            <el-input
              v-model="formData.okPassword"
              :placeholder="translateTitle('请输入密码')"
              :type="showOkPassword ? 'text' : 'password'"
            >
              <template #suffix>
                <div class="suffix-btn">
                  <ClearIcon
                    v-if="!!formData.okPassword"
                    style="margin-right: 10px"
                    @click="formData.okPassword = ''"
                  />
                  <EyeIcon
                    v-if="showOkPassword"
                    @click="showOkPassword = !showOkPassword"
                  />
                  <EyeHideIcon
                    v-else
                    @click="showOkPassword = !showOkPassword"
                  />
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>

        <el-button
          class="next-btn"
          :disabled="
            Object.values(validObject).some(state => !state) ||
            !passwordInputValid
          "
          type="primary"
          @click="updatePassword"
        >
          <span v-if="!loading">{{ translateTitle("下一步") }}</span>
          <Loading v-else />
        </el-button>
      </div>
    </div>

    <el-dialog
      :visible="showToLoginDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="376px"
      class="custom-dialog"
      top="0"
    >
      <div class="icons">
        <img :src="resetTip" width="46" alt="" />
      </div>
      <div class="title2">{{ translateTitle("密码修改成功") }}</div>
      <div class="tip-desc">
        {{ translateTitle("您的密码已修改成功，请使用新密码重新登录。") }}
      </div>
      <el-button class="next-btn" type="primary" @click="toLogin">
        <span v-if="!loading">{{ translateTitle("登录") }}</span>
        <Loading v-else />
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import ClearIcon from "@/components/icons/ClearIcon.vue";
import { UCenterRequest } from "@/model/UCenterService";
import md5 from "md5";
import Loading from "@/components/common/Loading.vue";
import warn2Icon from "@/assets/img/users/warn2.png";
import resetTip from "@/assets/img/users/reset-tip.png";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import EyeHideIcon from "@/components/icons/EyeHideIcon.vue";
import CheckFailIcon from "@/components/icons/CheckFailIcon.vue";
import CheckSuccessIcon from "@/components/icons/CheckSuccessIcon.vue";

const passwordReg =
  /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/;
export default {
  components: {
    ClearIcon,
    Loading,
    EyeIcon,
    EyeHideIcon,
    CheckFailIcon,
    CheckSuccessIcon,
  },
  data() {
    return {
      logoIcon,
      warn2Icon,
      resetTip,
      formRules: {
        okPassword: [
          {
            required: true,
            message: this.translateTitle("请再次输入密码"),
            trigger: "blur",
          },
        ],
      },
      formData: {
        password: "",
        okPassword: "",
      },
      loading: false,
      showToLoginDialog: false,
      showPassword: false,
      showOkPassword: false,
    };
  },
  computed: {
    validObject() {
      return {
        full:
          passwordReg.test(this.formData.password) &&
          this.formData.password.length >= 6 &&
          this.formData.password.length <= 20,
        char: /[a-zA-Z]+/.test(this.formData.password),
        number: /[0-9]+/.test(this.formData.password),
      };
    },
    passwordInputValid() {
      return this.formData.okPassword === this.formData.password;
    },
  },
  created() {
    if (this.$route.query.tokenVerify) {
    }
  },
  methods: {
    async updatePassword() {
      const tokenVerifyStr = this.$route.query.tokenVerify;
      let tokenVerify = "";
      if (tokenVerifyStr) {
        const data = JSON.parse(window.decodeURIComponent(tokenVerifyStr));
        tokenVerify = Object.values(data).filter(Boolean)[0];
      }

      const res = await UCenterRequest.checkForgetSafetyVerify({
        token: this.$route.params.token,
        token_verify: tokenVerify,
        token_forget: this.$route.params.tokenForget,
      });
      if (res.status === 200) {
        const resetRes = await UCenterRequest.resetPasswordByForget({
          token: this.$route.params.token,
          pwd: md5(this.formData.password),
          token_forget: this.$route.params.tokenForget,
        });
        if (resetRes.status === 200) {
          // this.toLogin();
          this.showToLoginDialog = true;
        }
      }
    },
    toLogin() {
      window.location.href = "/login";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common.scss";
.account {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-top: 12px;
}
.card-container {
  height: auto !important;
  min-height: 520px !important;
  .form {
    margin-top: 24px !important;
  }
}
.next-btn {
  margin-top: 0px !important;
}
.other-btn {
  text-align: left !important;
}

.tips {
  margin-top: 24px;
  display: flex;
  align-items: center;
  background: rgba(51, 72, 246, 0.06);
  color: #495bf6;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  padding: 12px 10px;
  border: 1px solid rgba(2, 96, 231, 0.1);
  border-radius: 10px;
  img {
    margin-right: 8px;
  }
}

.icons {
  display: flex;
  justify-content: space-around;
}

.title2 {
  color: var(--7-c_t01, #0d0e0e);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 90% */
  margin-top: 16px;
}
.tip-desc {
  margin-top: 16px;
  color: #5c5f69;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.5px;
}

.custom-dialog {
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep {
    .el-dialog {
      border-radius: 12px;
      margin-bottom: 0 !important;
    }
    .el-dialog__header {
      display: none;
    }
  }
}
</style>
